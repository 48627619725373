<template>
  <div
    class="wrapper"
    :class="[{ 'nav-open': $sidebar.showSidebar },]"
  >
    <notifications />
    <side-bar
      :background-image="sidebarBackgroundImage"
    >
      <user-menu
        :title="user.first_name+' '+user.last_name"
        :img="user.image_url || avatar"
      />
      <mobile-menu />
      <template slot="links">
        <template
          v-for="menu of sortedMenus"
        >
          <sidebar-item
            v-if="menu.children"
            :key="menu.name"
            :link="{ name: menu.name, icon: menu.icon }"
          >
            <sidebar-item
              v-for="submenu of menu.children"
              :key="submenu.title"
              :link="{ name: submenu.title, path: { name: submenu.routeName } }"
            />
          </sidebar-item>

          <sidebar-item
            v-else
            :key="menu.title"
            :link="{ name: menu.name, icon: menu.icon, path: { name: menu.path } }"
          />
        </template>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar />

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition
          :duration="200"
          mode="out-in"
        >
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>

    <div v-if="chatActive">
      <Chat />
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import Chat from '@/components/Chat/Chat.vue';
import menusFile from '@/routes/menus';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import MobileMenu from './Extra/MobileMenu.vue';
import UserMenu from './Extra/UserMenu.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
    Chat,
  },
  data() {
    return {
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'azure',
      sidebarBackgroundImage: '/img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true,
      sortedMenus: [],
      menus: menusFile,
    };
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
  updated() {
  },
  mounted() {
    this.filterMenus();

    this.$store.dispatch('visaTypes/getOptions', {});
  },
  methods: {
    filterMenus() {
      const { roles } = JSON.parse(localStorage.user);
      let menus = [];

      // If user is not ADMIN we check the endpoints he can access.
      if (!roles.map((x) => x.role_id).includes(1)) {
        const endpoints = new Set(
          roles.flatMap((x) => x.endpoints.flatMap((y) => y.endpoint.name)).flat(),
        );

        menus = this.menus
          .map((x) => {
            if (!x.children) return x;

            const children = x.children.filter((y) => {
              if (!y.endpoints) return true;

              let childValid = false;
              y.endpoints.forEach((z) => {
                if (endpoints.has(z)) childValid = true;
              });

              return childValid;
            });

            return {
              ...x,
              children,
            };
          })
          .filter((x) => x.name === 'Dashboard' || x.children.length);
      } else {
        menus = this.menus;
      }
      this.sortMenus(menus);
    },
    sortMenus(menus) {
      this.sortedMenus = menus.map((x) => {
        if (x.children && x.children.length) {
          return {
            name: x.name,
            icon: x.icon,
            children: x.children.sort((a, b) => {
              if (a.title < b.title) return -1;
              return 1;
            }),
          };
        }
        return {
          name: x.name,
          icon: x.icon,
          path: x.routeName,
        };
      })
        .filter((x) => {
          if (x.name === 'Progress Exit Report' && !this.exitReportActive) return false;
          return true;
        })
        .sort((a, b) => {
          if (b.name === 'Dashboard') return 1;
          if (a.name < b.name) return -1;
          return 1;
        });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
};
</script>
<style lang="scss">
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }

  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }

  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }

  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
  .md-checkbox.md-disabled .md-checkbox-label,
  .md-checkbox .md-checkbox-label {
    opacity: 100% !important;
    color: #495057 !important;
  }

</style>
