<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          {{ $route.meta.topbar || $route.name }}
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <md-list>
            <!--<li class="md-list-item">
              <a
                href="#/components/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>-->

            <li class="md-list-item copyright">
              <p>
                &copy; {{ new Date().getFullYear() }} <a>Kmpus</a> - v{{
                  version
                }}
              </p>
            </li>

            <router-link
              v-if="userCanOpenSettings"
              :to="{ name: 'SchoolSettings' }"
              tag="md-list-item"
            >
              <i class="material-icons">settings</i>
              <p class="hidden-lg hidden-md">
                School Settings
              </p>
              <md-tooltip>School Settings</md-tooltip>
            </router-link>

            <router-link
              :to="{ name: 'UserProfile' }"
              tag="md-list-item"
            >
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">
                Profile
              </p>
              <md-tooltip>Profile</md-tooltip>
            </router-link>

            <md-list-item
              href="#"
              @click="onLogout"
            >
              <i class="material-icons">logout</i>
              <p class="hidden-lg hidden-md">
                Logout
              </p>
              <md-tooltip>Logout</md-tooltip>
            </md-list-item>

            <md-list-item
              v-if="adminToken"
              href="#"
              @click="logoutToAdmin"
            >
              <i class="material-icons">exit_to_app</i>
              <p class="hidden-lg hidden-md">
                Return to Admin user
              </p>
              <md-tooltip>Return to Admin user</md-tooltip>
            </md-list-item>

            <md-list-item href="/lock">
              <i class="material-icons">lock</i>
              <p class="hidden-lg hidden-md">
                Lock screen
              </p>
              <md-tooltip>Lock Screen</md-tooltip>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data: () => ({
    version: localStorage.getItem('kmpus_version') || null,
    selectedEmployee: '',
    employees: [
      'Jim Halpert',
      'Dwight Schrute',
      'Michael Scott',
      'Pam Beesly',
      'Angela Martin',
      'Kelly Kapoor',
      'Ryan Howard',
      'Kevin Malone',
    ],
  }),
  computed: {
    adminToken() {
      return localStorage.getItem('admin_token');
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    onLogout() {
      this.cleanSession();
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-list-item.copyright {
  font-size: 0.75em;
  padding-right: 1em;
  a {
    display: inline;
  }
}
</style>
