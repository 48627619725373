<template>
  <div class="chat-container">
    <div class="chat">
      <!-- USERS LIST -->
      <div
        class="chat-users"
        :class="{
          opened: showUsers
        }"
      >
        <div
          class="chat-users-header"
          @click="toggleUsers"
        >
          <h5>{{ onlineUsers }} User{{ onlineUsers > 1 ? 's' : null }} Connected</h5>
          <div
            v-if="unreadMessages"
            class="badge unread"
          >
            {{ unreadMessages }}
          </div>
          <md-icon>{{ showUsers ? 'expand_more' : 'expand_less' }}</md-icon>
        </div>
        <div class="chat-users-content">
          <div
            v-for="user of users"
            :key="user.user_id"
            class="chat-user"
            :class="{ 'connected': user.online }"
            @click="openChatWindow(user)"
          >
            <div
              class="profile-pic"
              :style="{backgroundImage: `url('${user.image_url || avatar}')`}"
            >
              <small
                v-if="user.unreadMessages"
                class="badge"
              >{{ user.unreadMessages }}</small>
            </div>
            <p>
              {{ user.name }}
            </p>
          </div>
        </div>
      </div>
      <!-- CHATS WINDOWS -->
      <div
        v-for="c of chats"
        :key="c.id"
        class="chat-window"
        :class="{
          opened: windowOpenedId === c.id
        }"
      >
        <div class="chat-window-header">
          <h5 @click="toggleChatWindow(c.id)">
            {{ c.user_name }}
          </h5>
          <md-button
            class="md-simple md-just-icon"
            @click="closeChatWindow(c.id)"
          >
            <md-icon>
              close
            </md-icon>
          </md-button>
        </div>
        <div
          :id="`chat-window-content-${c.id}`"
          class="chat-window-content"
        >
          <div
            v-for="m of c.messages"
            :key="m.message_id"
            class="chat-item"
            :class="{
              'toMe': m.from_id !== user.user_id
            }"
          >
            <p>{{ m.message }}</p>
            <small>{{ dateTimeFormat(m.created_at) }}</small>
          </div>
        </div>
        <div class="chat-window-footer">
          <input
            v-model="messageToSend"
            type="text"
            placeholder="Your message..."
            @keypress.enter="sendMessage(c.id)"
          >
          <md-button
            class="md-primary md-sm md-just-icon md-simple"
            @click="sendMessage(c.id)"
          >
            <md-icon>
              send
            </md-icon>
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO(process.env.VUE_APP_CHAT_SOCKET, {
    debug: true,
    autoConnect: false,
    extraHeaders: {
      domain: document.location.host,
    },
  }),
}));

/**
 * Al momento de conectarse:
 * Traer listado de usuarios. con online status
 * y badge con cant de mensajes sin leer por el usuario
 * ordenarlos por nombre de usuario. pero traer primero los q tengan msj sin leer
 *
 * Al clickear en un usuario:
 * Abrir nueva ventana de chat, si ya hay 3 chats abiertos. cerrar primero
 *
 * Al abrir ventana de chat:
 * Traer mensajes con ese usuario
 *
 * Al enviar mensaje:
 * Emitir mensajes para usuarios
 */

export default {
  data: () => ({
    showUsers: false,
    windowOpenedId: null,
    messageToSend: null,
    chats: [],
    users: [],
    unreadMessages: 0,
  }),
  computed: {
    onlineUsers() {
      return this.users.filter((x) => x.online).length;
    },
  },
  mounted() {
    window.addEventListener('click', this.clickOutsideUsersListener);
    this.$socket.connect();
  },
  sockets: {
    /**
     * On connection, send User data to Server
     */
    connect() {
      console.log('connected');
      this.$socket.emit('set_user', this.user.user_id);
    },
    // When receiving users_list, save this array to display
    users_list(data) {
      if (!this.users.length) {
        this.users = data.filter((x) => x.user_id !== this.user.user_id);
      } else {
        for (const u of this.users) {
          const dataU = data.filter((x) => x.user_id === u.user_id);

          if (dataU.length) {
            u.online = dataU[0].online;
          }
        }
      }
    },
    messages(data) {
      this.unreadMessages = data.filter((x) => this.unreadFilter(x)).length;

      this.users = this.users.map((u) => {
        // Fill messages for each user
        const messages = data
          .filter(
            (x) => [x.to_id, x.from_id].includes(u.user_id)
            && [x.to_id, x.from_id].includes(this.user.user_id),
          );
        u.messages = messages;
        u.unreadMessages = messages.filter((x) => this.unreadFilter(x)).length;

        // Fill opened chat if exists & scroll down
        const chat = this.chats.filter((x) => x.id === u.user_id);
        if (chat.length) {
          chat[0].messages = messages;

          this.scrollBottomChat(chat[0].id);
        }

        return u;
      });

      // Sort users by unread messages
      this.users.sort((a, b) => {
        if (a.unreadMessages > b.unreadMessages) return -1;
        return 1;
      });
    },
    error(data) {
      this.fireError(data);
    },
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutsideUsersListener);
  },
  methods: {
    // show/hide users list
    toggleUsers() {
      this.showUsers = !this.showUsers;
    },
    // hide users list if clicked outside
    clickOutsideUsersListener(e) {
      const usersCont = document.querySelector('.chat-users');

      if (!usersCont.contains(e.target)) {
        this.showUsers = false;
      }
    },

    // show/hide chat window
    toggleChatWindow(id) {
      if (this.windowOpenedId === id) this.windowOpenedId = null;
      else {
        this.windowOpenedId = id;
        this.$socket.emit('read_messages', { reader_id: this.user.user_id, sender_id: id });
      }
    },
    // close chat window
    closeChatWindow(id) {
      this.chats.splice(this.chats.findIndex((x) => x.id === id), 1);
    },
    // open chat window
    openChatWindow(user) {
      // If chat is already open. just focus it.
      if (this.chats.map((x) => x.id).includes(user.user_id)) {
        this.windowOpenedId = user.user_id;

        this.scrollBottomChat(user.user_id);
        return;
      }

      // If there is more than 3 chats opened, close first
      if (this.chats.length > 3) this.chats.splice(0, 1);

      // Clear message to send
      this.messageToSend = null;

      // Create chat window, and focus it
      this.chats.push({
        id: user.user_id,
        user_name: user.name,
        messages: user.messages,
      });
      this.windowOpenedId = user.user_id;

      this.scrollBottomChat(user.user_id);
      this.$socket.emit('read_messages', { reader_id: this.user.user_id, sender_id: user.user_id });
    },

    // Send Message to user
    sendMessage(to) {
      // emit event
      this.$socket.emit('send_message', {
        to,
        from: this.user.user_id,
        message: this.messageToSend,
      });

      // Clear message to send
      this.messageToSend = null;
    },

    unreadFilter(x) {
      if (!x) return false;
      if (x.from_id !== this.user.user_id) return !x.seen;
      return false;
    },

    scrollBottomChat(id) {
      setTimeout(() => {
        const chatContent = document.getElementById(`chat-window-content-${id}`);

        if (chatContent) {
          chatContent.scrollTop = chatContent.scrollHeight;
        }
      }, 100);
    },

  },
};
</script>

<style scoped lang="scss">
.chat-container {
  height: 1px;
  overflow: visible;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  pointer-events: none;

  .chat {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    bottom: 0;
    left:0;
    right: 0;
    transform: translateY(451px);
    transition: .5s;
    padding-right: 30px;
    pointer-events: none;

    .chat-users, .chat-window {
      pointer-events: auto;
      width: 250px;
      background: #fff;
      border: 1px solid #ccc;
      border-top-left-radius: .5em;
      border-top-right-radius: .5em;
      overflow: hidden;
      transition: .5s;
      margin-right: .25em;

      &-header {
        height: 40px;
        background-image: linear-gradient(60deg, rgb(38, 198, 218), rgb(0, 172, 193));
        display: flex;
        color: white;
        padding: .5em;
        align-items: center;
        cursor: pointer;

        h5 {
          flex-grow: 1;
          margin:0;
          font-weight: 600;
        }

        .md-icon {
          color: white;
        }

        .badge {
          height: 25px;
          width: 25px;
          background: #f44336;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-content {
        height: 450px;
        overflow-y: auto;
        overflow-x: hidden;

        .chat-user {
          display: flex;
          align-items: center;
          padding: 0 1em;
          cursor: pointer;

          .profile-pic {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
            height: 35px;
            flex: 0 0 35px !important;
            border: 2px solid #ccc;
            margin-right: 1em;
            display: block;
            position: relative;

            .badge {
              height: 20px;
              width: 20px;
              background: #f44336;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: -8px;
              right: -8px;
              padding: 0;
            }
          }

          p {
            font-size: .9em;
            flex-grow: 1;
          }

          &:hover {
            background: rgba(0,0,0,.1);
          }

          &.connected .profile-pic {
            border: 2px solid rgb(57, 221, 57);
          }
        }

        .chat-item {
          max-width: 85%;
          padding: 0.5em;
          background: rgb(226, 226, 226);
          border-radius: .5em;
          border-top-left-radius: 0;
          margin: 0.5em;

          &.toMe {
            border-top-left-radius: .5em;
            border-top-right-radius: 0;
            margin-left: auto;
            background: #c9e0f3;
            text-align: right;
          }

          p {
            margin: 0;
          }

          small {
            font-size: .6em;
            display: block;
            line-height: 1em;
            margin-top: .5em;
          }
        }
      }

      &.opened {
        transform: translateY(-451px);
        transition: .5s;
      }
    }

    .chat-window {
      &-header {
        background: rgb(238, 237, 237);
        color: rgb(74, 76, 77);

        .md-icon {
          color: rgb(74, 76, 77);
        }
      }

      &-content {
        height: 410px;
      }

      &-footer {
        padding: .25em .5em;
        height: 40px;
        border-top: 1px solid #ccc;
        display: flex;
        align-items: center;

        input {
          height: 100%;
          margin: 0;
          flex-grow: 1;
          border: unset;
          border-bottom: 1px solid #ccc;

          &:focus {
            border-bottom: 1px solid rgb(156, 39, 176);
          }
        }
      }
    }
  }
}
</style>
