<template>
  <div class="user">
    <div class="photo">
      <img
        :src="img"
        alt="avatar"
      >
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>
          {{ title }}
          <b class="caret" />
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <router-link :to="{name:'UserProfile'}">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </router-link>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: 'Francisco Olivares',
    },
    img: {
      type: String,
      default: '/img/default-avatar.png',
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    clicked(e) {
      e.preventDefault();
    },
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
