export default [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    routeName: 'Dashboard',
  },
  {
    name: 'Admin',
    icon: 'apps',
    children: [
      {
        title: 'Users',
        routeName: 'Users',
        endpoints: [
          'users.index',
          'users.show',
          'users.create',
          'users.update',
          'users.destroy',
        ],
      },
      {
        title: 'System Parameters',
        routeName: 'SystemParameters',
        endpoints: ['system.index', 'system.update'],
      },
      {
        title: 'Sales Sources',
        routeName: 'MarketingSources',
        endpoints: [
          'marketing_sources.index',
          'marketing_sources.create',
          'marketing_sources.update',
          'marketing_sources.delete',
        ],
      },
      {
        title: 'Templates',
        routeName: 'Templates',
        endpoints: ['html_templates.index', 'html_templates.update'],
      },
      {
        title: 'Templates - Letters',
        routeName: 'LetterTemplates',
        endpoints: ['letter_templates.index', 'letter_templates.update'],
      },
      {
        title: 'Package Items',
        routeName: 'PackageItems',
        endpoints: [
          'package_items.index',
          'package_items.create',
          'package_items.update',
          'package_items.destroy',
        ],
      },
      {
        title: 'Departments',
        routeName: 'Departments',
        endpoints: [
          'departments.index',
          'departments.show',
          'departments.create',
          'departments.update',
          'departments.destroy',
        ],
      },
      {
        title: 'Roles',
        routeName: 'Roles',
        endpoints: [
          'roles.index',
          'roles.show',
          'roles.create',
          'roles.update',
          'roles.destroy',
        ],
      },
      {
        title: 'Payment Methods',
        routeName: 'PaymentMethods',
        endpoints: [
          'payment_methods.index',
          'payment_methods.show',
          'payment_methods.create',
          'payment_methods.update',
          'payment_methods.destroy',
        ],
      },
      {
        title: 'Holidays',
        routeName: 'HolidayDays',
        endpoints: [
          'holiday_days.index',
          'holiday_days.show',
          'holiday_days.create',
          'holiday_days.update',
          'holiday_days.destroy',
        ],
      },
      {
        title: 'Finance Categories',
        routeName: 'MoneyflowCategories',
        endpoints: [
          'moneyflow_categories.index',
          'moneyflow_categories.show',
          'moneyflow_categories.create',
          'moneyflow_categories.update',
        ],
      },
      {
        title: 'Courses',
        routeName: 'Courses',
        endpoints: [
          'courses.index',
          'courses.create',
          'courses.update',
          'courses.destroy',
        ],
      },
      {
        title: 'Cancel Reasons',
        routeName: 'CancelReasons',
        endpoints: [
          'cancel_reasons.index',
          'cancel_reasons.create',
          'cancel_reasons.update',
          'cancel_reasons.destroy',
        ],
      },
      {
        title: 'Class Schedules',
        routeName: 'ClassSchedules',
        endpoints: [],
      },
      {
        title: 'Machines IPs',
        routeName: 'MachinesIPs',
        endpoints: [
          'classroom_machines.index',
          'classroom_machines.create',
          'classroom_machines.update',
          'classroom_machines.destroy',
          'staff_machines.index',
          'staff_machines.create',
          'staff_machines.update',
          'staff_machines.destroy',
        ],
      },
      {
        title: 'Buildings',
        routeName: 'Buildings',
        endpoints: [
          'buildings.index',
          'buildings.create',
          'buildings.update',
          'buildings.destroy',
        ],
      },
      {
        title: 'Custom Files Upload',
        routeName: 'CustomFilesUpload',
        endpoints: [
          'file_types_admin.types',
          'file_types_admin.index',
          'file_types_admin.create',
          'file_types_admin.show',
          'file_types_admin.destroy',
        ],
      },
      {
        title: 'Agent Transfer',
        routeName: 'AgentTransfer',
        endpoints: [
          'transfer_students.index',
          'transfer_students.create',
        ],
      },
      {
        title: 'School Settings',
        routeName: 'SchoolSettings',
        endpoints: [
          'school_settings.show',
          'school_settings.create',
        ],
      },
      {
        title: 'Exam Types',
        routeName: 'ExamTypes',
        endpoints: [
          'exam_types.index',
          'exam_types.create',
          'exam_types.update',
          'exam_types.delete',
        ],
      },
    ],
  },
  {
    name: 'Academic',
    icon: 'group',
    children: [
      {
        title: 'Teachers',
        routeName: 'Teachers',
        endpoints: [
          'teachers.index',
          'teachers.show',
          'teachers.create',
          'teachers.update',
          'teachers.destroy',
        ],
      },
      {
        title: 'Students',
        routeName: 'Students',
        endpoints: [
          'placement_test.student.show',
          'students.index',
          'students.create',
          'students.show',
          'students.update',
          'students.course_progress',
          'students.programmes.index',
          'students.programmes.create',
          'students.files.index',
          'students.files.create',
          'students.files.download',
          'students.files.destroy',
          'passports.destroy',
          'students.passports.update',
          'programmes.approve_deny',
        ],
      },
      {
        title: 'Programmes',
        routeName: 'Programmes',
        endpoints: [
          'programmes.index',
          'programmes.show',
          'programmes.update',
          'programmes.approve_deny',
          'programmes.update',
          'programmes.enrolments.index',
          'programmes.enrolments.create',
          'programmes.enrolments.update',
          'programmes.enrolments.destroy',
          'programmes.attendances.index',
          'programmes.attendances.update',
          'programmes.holidays.index',
          'programmes.holidays.create',
          'programmes.holidays.update',
          'programmes.holidays.destroy',
          'programmes.letters.index',
          'programmes.letters.create',
          'programmes.letters.destroy',
          'programmes.letters.show',
          'programmes.letters.update',
          'programmes.assessments.index',
          'programmes.remarks.index',
          'programmes.remarks.create',
          'programmes.remarks.update',
          'programmes.remarks.destroy',
          'programmes.letters.download',
        ],
      },
      {
        title: 'Attendances',
        routeName: 'Attendances',
        endpoints: [
          'attendances.lists',
          'attendances.report',
          'attendances.report.xls',
          'attendance_lists.create',
          'attendance_lists.details',
          'attendance_lists.download',
          'attendance_lists.update',
          'attendance_lists.delete',
        ],
      },
      {
        title: 'Classrooms',
        routeName: 'Classrooms',
        endpoints: [
          'classrooms.index',
          'classrooms.create',
          'classrooms.update',
          'classrooms.show',
          'classrooms.destroy',
          'classrooms.enrolment.index',
        ],
      },
      {
        title: 'Assessments',
        routeName: 'Assessments',
        endpoints: [
          'assessment_enrolments.update',
          'assessments.index',
          'assessments.create',
          'assessments.show',
          'assessments.update',
          'assessments.delete',
          'assessments.entries',
        ],
      },
      {
        title: 'Contingency Map',
        routeName: 'ContingencyMap',
        endpoints: ['contingency_map.index'],
      },
    ],
  },
  // {
  //   name: 'CRM',
  //   icon: 'manage_accounts',
  //   children: [
  //     {
  //       title: 'Segments',
  //       routeName: 'Segments',
  //       endpoints: [
  //         'crm.segments.index',
  //         'crm.segments.create',
  //         'crm.segments.update',
  //       ],
  //     },
  //     {
  //       title: 'Campaigns',
  //       routeName: 'Campaigns',
  //       endpoints: [
  //         'crm.campaigns.index',
  //         'crm.campaigns.create',
  //         'crm.campaigns.update',
  //       ],
  //     },
  //     {
  //       title: 'Pipelines',
  //       routeName: 'Pipelines',
  //       endpoints: [
  //         'crm.pipelines.index',
  //         'crm.pipelines.create',
  //         'crm.pipelines.update',
  //         'crm.pipelines.updateOrder',
  //         'crm.pipelines.delete',
  //       ],
  //     },
  //     {
  //       title: 'Pipeline Leads',
  //       routeName: 'PipelineLeads',
  //       endpoints: [
  //         'crm.pipeline_leads.index',
  //         'crm.pipeline_leads.create',
  //         'crm.pipeline_leads.update',
  //         'crm.pipeline_leads.delete',
  //       ],
  //     },
  //   ],
  // },
  {
    name: 'Sales',
    icon: 'shopping_cart',
    children: [
      {
        title: 'Commissions',
        routeName: 'Commissions',
        endpoints: ['agents.commissions_report'],
      },
      {
        title: 'Agencies',
        routeName: 'Agencies',
        endpoints: ['agencies.index', 'agencies.create', 'agencies.destroy'],
      },
      {
        title: 'Packages',
        routeName: 'Packages',
        endpoints: [
          'package_items.index',
          'package_items.create',
          'package_items.update',
          'package_items.destroy',
          'packages.scheduled_payments.index',
          'packages.scheduled_payments.update',
          'packages.index',
          'packages.show',
          'packages.update',
          'packages.approve.deny',
        ],
      },
      {
        title: 'Application Forms',
        routeName: 'Forms',
        endpoints: [
          'student_form.index',
          'student_form.create',
          'student_form.update',
          'student_form.approve',
        ],
      },
      {
        title: 'Agent Groups',
        routeName: 'AgentGroups',
        endpoints: [
          'agent_groups.index',
          'agent_groups.create',
          'agent_groups.update',
          'agent_groups.destroy',
        ],
      },
      {
        title: 'Student Letters',
        routeName: 'StudentLetters',
        endpoints: ['student_letters.report'],
      },
    ],
  },
  {
    name: 'Finance',
    icon: 'money',
    children: [
      {
        title: 'Scheduled Payments',
        routeName: 'ScheduledPayments',
        endpoints: [
          'scheduled_payments.index',
          'scheduled_payments.deny',
          'scheduled_payments.approve',
          'scheduled_payments.download',
        ],
      },
      {
        title: 'Expenditure',
        routeName: 'MoneyOutflow',
        endpoints: ['moneyflow.create', 'moneyflow.download'],
      },
      {
        title: 'Income',
        routeName: 'MoneyInflow',
        endpoints: ['moneyflow.create', 'moneyflow.download'],
      },
      {
        title: 'Commissions',
        routeName: 'FinanceCommissions',
        endpoints: ['agents.commissions_report'],
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'assessment',
    children: [
      {
        title: 'Assessments',
        routeName: 'AssessmentsReport',
        endpoints: ['assessments.report'],
      },
      {
        title: 'Agencies',
        routeName: 'AgenciesReport',
        endpoints: ['agencies.report'],
      },
      {
        title: 'Application Forms',
        routeName: 'ApplicationFormsReport',
        endpoints: ['application_forms.report'],
      },
      {
        title: 'Package Items',
        routeName: 'PackageItemsReport',
        endpoints: [
          'packages.items.report',
          'packages.items.setUsed',
        ],
      },
      {
        title: 'IRP',
        routeName: 'IrpReport',
        endpoints: ['students.gnib.report'],
      },
      {
        title: 'Placement Test',
        routeName: 'PlacementTestReport',
        endpoints: ['placement_test.report'],
      },
      {
        title: 'Income / Expenditure',
        routeName: 'OutflowInflow',
        endpoints: ['moneyflow.report'],
      },
      {
        title: 'Sales per Country',
        routeName: 'SalesPerCountry',
        endpoints: ['sales_per_country.report'],
      },
      {
        title: 'Source of Sales',
        routeName: 'SourceOfSale',
        endpoints: ['source_of_sales.report'],
      },
      {
        title: 'Attendance',
        routeName: 'AttendanceReport',
        endpoints: ['attendances.report', 'attendances.report.xls'],
      },
      {
        title: 'Attendance / Date',
        routeName: 'AttendanceByDateReport',
        endpoints: ['attendance_by_date.report'],
      },
      {
        title: 'Insurance',
        routeName: 'InsuranceReport',
        endpoints: ['insurances.report'],
      },
      {
        title: 'Income Projection',
        routeName: 'IncomeProyection',
        endpoints: ['income_projection.report'],
      },
      {
        title: 'Finished Students',
        routeName: 'FinishedStudentsReport',
        endpoints: ['finished_students.report', 'finished_students.report.xls'],
      },
      {
        title: 'Programmes',
        routeName: 'ProgrammesReport',
        endpoints: ['programmes_report.report'],
      },
      {
        title: 'Students',
        routeName: 'StudentsReport',
        endpoints: ['students_report.report'],
      },
      {
        title: 'Students Exam',
        routeName: 'StudentsExamReport',
        endpoints: ['students_exam.report'],
      },
      {
        title: 'End Exam',
        routeName: 'EndExam',
        endpoints: ['end_exam.report'],
      },
      {
        title: 'Students Accommodations',
        routeName: 'studentAccommodation',
        endpoints: ['students_accommodation.report'],
      },
      {
        title: 'Cancelled Programmes',
        routeName: 'cancelledProgrammes',
        endpoints: ['programmes.cancel_reasons_report'],
      },
    ],
  },
  {
    name: 'Clocking System',
    icon: 'watch_later',
    children: [
      {
        title: 'Staff Time Records',
        routeName: 'StaffRecords',
        endpoints: ['staff_records.index'],
      },
      {
        title: 'Students Time Records',
        routeName: 'StudentsRecords',
        endpoints: ['students_records.index'],
      },
      {
        title: 'Machines Map',
        routeName: 'MachinesMap',
        endpoints: [
          'biometric.machines',
          'biometric.machines.log',
          'biometric.machines.sync',
        ],
      },
    ],
  },
  {
    name: 'APP',
    icon: 'mobile_friendly',
    children: [
      {
        title: 'Info',
        routeName: 'AboutUs',
        endpoints: ['app.about_us.show'],
      },
      {
        title: 'Banners',
        routeName: 'Banners',
        endpoints: [
          'app.banners.index',
          'app.banners.create',
          'app.banners.update',
          'app.banners.delete',
        ],
      },
      {
        title: 'Calendar',
        routeName: 'Calendar',
        endpoints: ['app.calendar.categories.index'],
      },
      {
        title: 'Push Notifications',
        routeName: 'PushNotifications',
        endpoints: ['app.notifications.create'],
      },
      {
        title: 'Notifications',
        routeName: 'Notifications',
        endpoints: ['app.notifications.index'],
      },
      {
        title: 'News',
        routeName: 'News',
        endpoints: ['app.news.index'],
      },
      {
        title: 'Activities',
        routeName: 'Activities',
        endpoints: ['app.activities.index'],
      },
      {
        title: 'Requests',
        routeName: 'Requests',
        endpoints: ['requests.index'],
      },
      {
        title: 'Request Types',
        routeName: 'RequestTypes',
        endpoints: ['request_types.index'],
      },
    ],
  },
  {
    name: 'Progress Exit Report',
    icon: 'rotate_right',
    children: [
      {
        title: 'Questions',
        routeName: 'PERQuestions',
        endpoints: [
          'per.questions.index',
          'per.questions.create',
          'per.questions.update',
          'per.questions.destroy',
        ],
      },
      {
        title: 'Schedules',
        routeName: 'PERSchedules',
        endpoints: [
          'per.schedules.index',
          'per.schedules.create',
          'per.schedules.update',
          'per.schedules.destroy',
        ],
      },
    ],
  },
  {
    name: 'Placement Test',
    icon: 'article',
    children: [
      {
        title: 'Questions',
        routeName: 'PlacementQuestions',
        endpoints: [
          'placement_test.questions.index',
          'placement_test.questions.create',
          'placement_test.questions.update',
          'placement_test.questions.destroy',
        ],
      },
      {
        title: 'Exams',
        routeName: 'PlacementExams',
        endpoints: [
          'placement_test.exams.index',
          'placement_test.exams.create',
          'placement_test.exams.update',
          'placement_test.exams.destroy',
        ],
      },
      {
        title: 'Schedules',
        routeName: 'PlacementSchedules',
        endpoints: [
          'placement_test.schedules.index',
          'placement_test.schedules.create',
          'placement_test.schedules.destroy',
          'placement_test.schedules.send_mail',
          'placement_test.schedules.send_mail_all',
        ],
      },
    ],
  },
];
